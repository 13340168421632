<template>
  <div>
    <dashboard-page-title
      :showSearch="false"
      :showFilter="false"
      :showMainActions="false"
      @addEvent="$router.push({ name: 'documents' })"
    >
      طلبات السحب
    </dashboard-page-title>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-col lg="9">
            <b-card class="mb-4">
              <b-row>
                <b-col>
                  <input-form
                    placeholder="اسم العميل"
                    label="اسم العميل"
                    v-model="documents.customer_name"
                    id="customer_name"
                    name="customer_name"
                    validate="required"
                    :disabled="$route.name === 'show-withdrawal-requests'"
                  ></input-form>
                </b-col>
                <b-col>
                  <input-form
                    placeholder="المهنة"
                    label="الدور"
                    v-model="documents.model_type"
                    id="model_type"
                    name="model_type"
                    validate="required"
                    :disabled="$route.name === 'show-withdrawal-requests'"
                  ></input-form>
                </b-col>
                <b-col></b-col>
              </b-row>
              <b-row>
                <b-col>
                  <input-form
                    placeholder="المبلغ المطلوب سحبه"
                    label="المبلغ المطلوب سحبه"
                    v-model="documents.amount"
                    id="amount"
                    name="amount"
                    validate="required"
                    :disabled="$route.name === 'show-withdrawal-requests'"
                  ></input-form>
                </b-col>
                <b-col>
                  <input-form
                    placeholder="IBAN"
                    label="رقم الحساب"
                    v-model="documents.bank_info.IBAN"
                    id="IBAN"
                    name="IBAN"
                    validate="required"
                    :disabled="$route.name === 'show-withdrawal-requests'"
                  ></input-form>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-row v-if="$route.name !== 'showDocuments'">
          <b-col
            md="9"
            class="d-flex justify-content-center align-items-center mt-5 gap_1"
          >
          <div>
            <b-button
              variant="primary"
              class="px-5 py-3"
              v-if="!loadingSubmit"
              @click="showAcceptedModal"
              id="acceptedModal"
              >{{ buttonText }}</b-button
            >
            <b-button
              v-else
              variant="primary"
              class="text-white px-5 py-3"
              disabled
              type="submit"
            >
              <b-spinner small type="grow"></b-spinner>
              Loading...
            </b-button>

          </div>
            <div>
                <b-button variant="outline-danger" class="px-5 py-3" id="show-btn" v-if="!loadingReject" @click="showSuspensedModal">رفض</b-button>
                <b-button v-else variant="outline-danger" class="text-white px-5 py-3" disabled type="submit">
                  <b-spinner small type="grow"></b-spinner>
                  Loading...
               </b-button>
            </div>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>

    <b-modal id="suspendedModal" size="lg" title="سبب ايقاف السحب" style="direction: rtl" centered hide-footer hide-header-close>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <b-row class="d-flex flex-wrap">
              <b-col>
                <textarea-form placeholder="السبب" v-model="reason" label="سبب مختصر" name="reason" validate="required"></textarea-form>
              </b-col>
            </b-row>
          </form>
        </ValidationObserver>

          <div class="d-flex justify-content-center mt-2">
            <b-button v-if='saveButton' class="btn btn-primary float-right" variant="primary" disabled>
              <spinner-loading text = 'Loading' />
            </b-button>
            <b-button variant="primary" v-else class="w-50" @click="handleSuspendHospital">Save</b-button>
          </div>
    </b-modal>

    <b-modal id="acceptedModal" size="lg" title="قبول السحب" style="direction: rtl" centered hide-footer hide-header-close>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <b-row class="d-flex flex-wrap">
              <b-col>
                <cropper-images
              label="رفع صورة الإيصال"
              nameOfImage="image.jpg"
              @cropper-save="saveMasterImage"
              :progressLoading="loadingLogo"
              :showProgress="false"
              :multi="false"
              :imageUrl="accepted.invoice"
          />
              </b-col>
              <b-col sm="12">
                <input-form placeholder="المصاريف" v-model="accepted.fee" label="مصاريف التحويل" name="fee" validate="required|numeric"></input-form>
              </b-col>
              <b-col sm="12">
                <input-form placeholder="معرف المعاملة" v-model="accepted.transaction_id" label="معرف المعاملة" name="transaction_id" validate="required"></input-form>
              </b-col>
              <b-col>
                <textarea-form placeholder="الملحوظات" v-model="accepted.notes" label="ملحوظات" name="reason" validate="required"></textarea-form>
              </b-col>
            </b-row>
          </form>
        </ValidationObserver>

          <div class="d-flex justify-content-center mt-2">
            <b-button v-if='saveButton' class="btn btn-primary float-right" variant="primary" disabled>
              <spinner-loading text = 'Loading' />
            </b-button>
            <b-button variant="primary" v-else class="w-50" @click="handleAccepted">Save</b-button>
          </div>
    </b-modal>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import api from '../../services/index'
import commonMixin from '@/mixins/commonMixin'

export default {
  components: {},
  mounted () {
    core.index()
  },
  mixins: [commonMixin],
  data () {
    return {
      loadingLogo: false,
      accepted: {
        transaction_id: '',
        notes: '',
        invoice: '',
        fee: ''
      },
      loading: false,
      loadingSubmit: false,
      documents: {
        customer_name: '',
        fee: ''
      }
    }
  },
  computed: {
    buttonText () {
      return this.$route.name === 'editDocuments' ? 'تعديل' : 'قبول'
    }
  },
  methods: {
    getdocuments () {
      api.getWithdrawalRequest(this.$route.params.id).then((res) => {
        this.documents = res.data
      })
    },
    showSuspensedModal (data) {
      this.suspendedId = data.id
      this.$bvModal.show('suspendedModal')
    },
    showAcceptedModal (data) {
      this.suspendedId = data.id
      this.$bvModal.show('acceptedModal')
    },
    handleSuspendHospital () {
      this.saveButton = true
      api.statusWithDrawal(this.$route.params.id, 'rejected', { mail_body: this.reason }).then(res => {
        core.showSnackbar('success', 'تم  ايقاف السحب بنجاح')
        this.saveButton = false
        this.$bvModal.hide('suspendedModal')
        this.reloadData = true
      })
      this.reloadData = false
    },
    handleAccepted () {
      this.saveButton = true
      api.statusWithDrawal(this.$route.params.id, 'accepted', this.accepted).then(res => {
        core.showSnackbar('success', 'تم  قبول السحب بنجاح')
        this.saveButton = false
        this.$bvModal.hide('suspendedModal')
        this.reloadData = true
      })
      this.reloadData = false
    },
    onSubmit () {
      if (this.$route.name === 'editDocuments') {
        this.editDocument()
      } else {
        this.loadingSubmit = true
        api
          .postDocument(this.documents)
          .then((res) => {
            core.showSnackbar('success', 'تم اضافة ')
            this.loadingSubmit = false
            this.$router.push({ name: 'documents' })
          })
          .catch(() => {
            this.loadingSubmit = false
          })
      }
    },
    editDocument () {
      this.loadingSubmit = true
      api.editDocument(this.$route.params.id, this.documents).then(() => {
        this.loadingSubmit = false
        core.showSnackbar('success', 'تم تعديل بنجاح')
        this.$router.push({ name: 'documents' })
      })
    },
    saveMasterImage (file, index) {
      const formData = new FormData()
      formData.append('file', file.image)
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          const percent = Math.floor((loaded * 100) / total)
          this.loadingLogo = percent
        }
      }
      this.commonUploadImages(formData, options)
        .then((res) => {
          this.accepted.invoice = res.data.url
          this.showSuccessUploadFile()
        })
        .catch((err) => {
          console.log(err)
        })
    }
  },
  created () {
    if (this.$route.params.id) {
      this.getdocuments()
      this.$root.$on('showSuspensedModal', this.showSuspensedModal)
      this.$root.$on('showAcceptedModal', this.showAcceptedModal)
    }
  }
}
</script>
    <style scoped>
.setting-box-title {
  min-width: 140px;
}
.setting-box-inner {
  margin-bottom: 30px;
}
</style>
